import { api } from '@api/index';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { formatDate } from '@lib/helpers/date';
import { AvailableLocales } from '@lib/route/types';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';
import { PriceFormat } from '@ui/price/PriceFormat';
import { Table } from '@ui/table/Table';
import { TableHeadCell } from '@ui/table/types';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { PaymentTableItems } from './types';

interface Props {
  locale: AvailableLocales;
  accountId: string;
}

export const Payments: FC<Props> = ({ locale, accountId }) => {
  const { tenant } = useTenantProps();
  const { t } = useTranslation('home');

  const { data: payments, isLoading } = useApiQuery(fetchPayments, {
    enabled: !!accountId,
    queryKey: [CACHE_KEYS.customerPayments(accountId)],
  });

  const tableHeaderCells: TableHeadCell<PaymentTableItems>[] = [
    {
      id: 'createdAt',
      formatLabel: () => t('auth.payments.table.header.createdAt'),
      formatRowValue: (value) =>
        value?.createdAt
          ? formatDate(value?.createdAt, 'DateWithTime', locale)
          : '-',
    },
    {
      id: 'type',
      formatLabel: () => t('auth.payments.table.header.type'),
      formatRowValue: (value) =>
        value?.type ? t(`auth.payments.table.types.${value?.type}`) : '-',
    },
    {
      id: 'amount',
      formatLabel: () => t('auth.payments.table.header.amount'),
    },
    {
      id: 'status',
      formatLabel: () => t('auth.payments.table.header.status'),
      formatRowValue: (value) =>
        value?.status
          ? t(`auth.payments.table.statuses.${value?.status}`)
          : '-',
    },
  ];

  const rows = payments?.content?.map((payment) => ({
    createdAt: payment?.createdAt,
    type: payment?.type,
    amount: (
      <PriceFormat
        price={payment.amount?.amount}
        currency={payment.amount?.currency}
      />
    ),
    status: payment?.status,
  }));

  return (
    <DefaultCard title={t('auth.payments.title')} noBorder={false}>
      <Table
        rows={rows ?? []}
        headCells={tableHeaderCells}
        orderBy="createdAt"
        order="desc"
        isLoading={isLoading}
      />
    </DefaultCard>
  );

  async function fetchPayments() {
    try {
      return await api.ocbDigital.customer.getCustomerPayments(
        tenant,
        accountId,
      );
    } catch (error) {
      throw error;
    }
  }
};
